import React, { memo, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { compose } from 'redux';
import { get } from 'immutable';

// instruments
import book from 'routes/book';
import { newsActions } from 'bus/news';

// components
import NewsPopup from 'components/news/Popup';

const EXCLUDED_PAGES = [book.news.path, book.news.children.single];

const visibleHoc = Component => () => {
  const pathname = useSelector(({ router }) => router.location.pathname);
  const isNewsPage = useMemo(() => {
    return EXCLUDED_PAGES.some(page => matchPath(pathname, { path: page, exact: true }));
  }, [pathname]);

  return !isNewsPage && <Component />;
};

const NewsOverlay = () => {
  const news = useSelector(state => state.news.getIn(['many', 'data']));

  const notViewedNews = useMemo(() => news.find(item => !item.is_viewed), [news]);
  const viewedUi = useSelector(({ ui }) => ui.getIn(['news', 'viewed', get(notViewedNews, 'id')], {}));

  // methods
  const dispatch = useDispatch();
  const confirmViewedNews = useCallback(() => dispatch(newsActions.viewedNews(get(notViewedNews, 'id'), { UI: true, START_AUTO_FETCH_NEWS: true })), [notViewedNews]);

  useEffect(() => {
    dispatch(newsActions.startAutoFetchNews());

    return () => {
      dispatch(newsActions.clearNews());
      dispatch(newsActions.endAutoFetchNews());
    };
  }, []);

  useEffect(() => {
    if (notViewedNews) {
      dispatch(newsActions.endAutoFetchNews());
    }
  }, [notViewedNews]);

  return notViewedNews && <NewsPopup {...notViewedNews} ui={viewedUi} onConfirmViewdNews={confirmViewedNews} />;
};

const enhancer = compose(visibleHoc, memo);

export default enhancer(NewsOverlay);
