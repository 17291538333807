import { select, call, put } from 'redux-saga/effects';
import { get } from 'immutable';

import { uiActions } from 'bus/ui/actions';
import { newsActions } from 'bus/news';
import { getNews } from 'api/methods/news';
import { authActions } from 'bus/auth/actions';

export function* getNewsWorker({ payload }) {
  const { options, queryParams } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const UI = get(options, 'UI', false);

  UI && (
    yield put(uiActions.changeUiLoaderFlag({
      status: { loading: true, error: false, message: null, completed: false },
      path: ['news', 'many'],
    }))
  );

  try {
    const {
      news: data,
      total,
      limit
    } = yield call(getNews, token, { queryParams: { ...queryParams || {} } });

    yield put(newsActions.getNewsSuccess({ data, total, limit }));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        return yield put(authActions.logOut(true));
      }
      default: message = 'Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['news', 'many', 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['news', 'many', 'message'] })));
    yield put(newsActions.getNewsFail(error));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['news', 'many', 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['news', 'many', 'completed'] })));
  }
}
