import { put, race, take, spawn, select, all, call } from 'redux-saga/effects';
import * as R from 'ramda';
import { LOCATION_CHANGE, replace } from 'connected-react-router';

import { getPathName } from 'bus/router/selectors';

import { deleteKeyFromCache, setQueryToCache } from 'helpers/cookies';

export const getDataFromSelectors = (selectors, mapper) => function* () {
  const params = yield all(R.map(selector => select(selector), selectors));

  return mapper(...params);
};

export const onSaveSearchToCache = cookieKey => function* (querySearchString) {
  if (R.isEmpty(cookieKey)) {
    yield call(deleteKeyFromCache, cookieKey);
  } else {
    yield call(setQueryToCache, cookieKey, querySearchString, { expires: 0.20 });
  }
};

export function* onDefaultChangeUrl(data) {
  const querySearchString = new URLSearchParams(data).toString();

  const pathname = yield select(getPathName);

  yield put(replace(`${pathname}?${querySearchString}`));
}

const noop = () => {};

export const withSetQuery2Url = (
  [successAction, failAction],
  callbacks
) => saga => function* (action) {
  yield spawn(saga, action);

  const [success] = yield race([
    take(successAction),
    take(failAction),
    take(LOCATION_CHANGE.toString())
  ]);

  if (success) {
    const { onGetData = noop, onSuccess = noop, onChangeUrl = onDefaultChangeUrl } = callbacks;

    try {
      const data = yield call(onGetData, action.payload);

      yield call(onChangeUrl, data);

      yield call(onSuccess, new URLSearchParams(data).toString());
    } catch (error) {
      console.error('An error occurred while changing the URL:', error);
    }
  }
};
