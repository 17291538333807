import { takeEvery, take, fork, cancel, race, call, join } from 'redux-saga/effects';

import { newsActions as actions } from 'bus/news';

import { onDefaultChangeUrl } from 'services/formCreator';

import { getNewsWorker } from './workers/getNews';
import { getSingleNewsWorker } from './workers/getSingleNews';
import { getNewsByTimeoutWorker } from './workers/getNewsByTimeout';
import { viewedNewsWorker } from './workers/viewedNews';
import getNewsSelectionsWorker from './workers/getNewsSelections';

export const newsWatchers = Object.freeze({
  * getNews() {
    yield takeEvery(actions.getNews, getNewsWorker);
  },
  * getNewsWithSavingQuery() {
    yield takeEvery(actions.getNewsWithSavingQuery, function* (action) {
      const task = yield fork(getNewsWorker, action);

      const [shouldCancelUpdateNews] = yield race([
        take(actions.clearNews),
        join(task)
      ]);

      if (shouldCancelUpdateNews) {
        yield cancel(task);
      } else {
        const { queryParams: { page, text, news_selection: selection } } = action.payload;

        yield call(onDefaultChangeUrl, {
          ...page === 1 ? {} : { page },
          ...selection ? { news_selection: selection } : { },
          ...text ? { text } : { },
        });
      }
    });
  },
  * getNewsSelections() {
    yield takeEvery(actions.getNewsSelections, getNewsSelectionsWorker);
  },
  * getSingleNews() {
    yield takeEvery(actions.getSingleNews, getSingleNewsWorker);
  },
  * viewedNews() {
    yield takeEvery(actions.viewedNews, viewedNewsWorker);
  },
  * getNewsByTimeout() {
    while (yield take(actions.startAutoFetchNews)) {
      const task = yield fork(getNewsByTimeoutWorker);

      yield take(actions.endAutoFetchNews);
      yield cancel(task);
    }
  },
});
